import * as React from 'react';
import { Box, Button, TextField } from '@mui/material';

function BasicLoginForm() {
    return (
        <div>
            <div className="heading-container">
                <h3>
                    Login Form
                </h3>
            </div>

            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="email" label="Enter the Email" variant="outlined" />
                <TextField id="password" label="Enter the Password" variant="outlined" />
            </Box>

            <BasicLoginButton />
        </div>
    );
}

function BasicLoginButton() {
    return (
        <Button variant="contained">Log in</Button>
    );
}

export default BasicLoginForm;